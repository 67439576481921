import { ModalBox } from '../../components/ModalBox';
import {
  Caption,
  CarouselCaptionWrapper,
  CarouselCard,
  CarouselContentWrapper,
  CarouselHeading,
  CarouselImageContainer,
  CarouselInner,
  ContentSliderWrapper,
  CurveWrapper,
  PanelCurveBottom,
  PanelCurveTop,
  SliderWrapper,
  StyledPlayContentSlider,
  VideoSlideContent,
} from './styles';

import type {
  Asset,
  CarouselComponent,
  Maybe,
} from 'server/repository/hygraph/generated/graphqlTypes';
import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';
import { MetaBallContainer } from '../../components/MetaBallContainer';
import NextImage from '../../components/NextImage';
import { Text as Paragraph, Title } from '../../components/Typography';
import { ImagesService } from 'service/images/imagesService';
import { Slider } from '@/components/SliderVanilla';

interface IContentSlider extends ISharedTemplateProps {
  templateProps: CarouselComponent;
  thumbnailUrls?: string[];
  embedUrl?: string;
  uploadDate?: string;
  duration?: string;
  interactionCount?: number;
  description?: string;
}

export const ContentSlider = ({
  templateProps: {
    carouselSlides: slides,
    heading,
    title,
    backgroundImg,
    bottomLeftAsset,
    topRightAsset,
  },
  description,
  thumbnailUrls,
  embedUrl,
  uploadDate,
  duration,
  interactionCount,
}: IContentSlider) => {
  const TopCurve = (
    <PanelCurveTop>
      <svg viewBox="0 0 320 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m 320,0 v 22.8436 c 0,0 -73.556,-19.843576 -160.667,-19.843584 C 72.2222,3.000009 3.77286e-6,22.8436 3.77286e-6,22.8436 L 0,0 Z"
          fill="#ffffff"
        />
      </svg>
    </PanelCurveTop>
  );

  const BottomCurve = (
    <PanelCurveBottom>
      <svg viewBox="0 0 320 22.677164" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M 0,22.677165 V 0 C 0,0 73.5557,19.8436 160.667,19.8436 247.778,19.8436 320,0 320,0 v 22.677165 z"
          fill="#ffffff"
        />
      </svg>
    </PanelCurveBottom>
  );

  const slideType = (sliderType: string, image?: Maybe<Asset>, videoId?: string) => {
    switch (sliderType) {
      case 'video': {
        return (
          <VideoSlideContent>
            <StyledPlayContentSlider>
              <ModalBox
                videoID={videoId}
                description={description || ''}
                thumbnailUrls={thumbnailUrls ?? []}
                embedUrl={embedUrl || ''}
                uploadDate={uploadDate || ''}
                duration={duration || ''}
                interactionCount={interactionCount || 0}
              />
            </StyledPlayContentSlider>
            <NextImage sources={ImagesService.getPictureSources(image)} alt="" />
          </VideoSlideContent>
        );
      }
      case 'image': {
        return <NextImage sources={ImagesService.getPictureSources(image)} alt="" />;
      }
      default: {
        return <NextImage sources={ImagesService.getPictureSources(image)} alt="" />;
      }
    }
  };

  return (
    <MetaBallContainer
      data-test="content-slider-metaball-container"
      topRightImage={topRightAsset?.url}
      bottomLeftImage={bottomLeftAsset?.url}
    >
      <ContentSliderWrapper
        data-test="content-slider-content-slider-wrapper"
        style={{ backgroundImage: `url(${backgroundImg?.url || ''})` }}
      >
        <CurveWrapper>
          {TopCurve}
          {BottomCurve}
        </CurveWrapper>
        <CarouselHeading data-test="content-slider-carousel-heading">
          {heading && (
            <Paragraph type="sm" tag="p" className="uppercase pb-8">
              {heading}
            </Paragraph>
          )}
          {title && (
            <Title type="sm" tag="h3" className="pb-8">
              {title}
            </Title>
          )}
        </CarouselHeading>
        <SliderWrapper data-test="content-slider-slider-wrapper">
          <Slider data-test="content-slider-slider" isFullWidth>
            {slides &&
              slides.length > 0 &&
              slides.map(({ videoId, slideAsset, caption, id }, index: number) => (
                <CarouselCard key={id ?? index}>
                  <CarouselContentWrapper data-test="content-slider-carousel-content-wrapper">
                    <CarouselCaptionWrapper>
                      <CarouselImageContainer>
                        <CarouselInner data-test="content-slider-carousel-inner">
                          {videoId
                            ? slideType('video', slideAsset, videoId)
                            : slideType('image', slideAsset)}
                        </CarouselInner>
                      </CarouselImageContainer>
                      {caption && <Caption data-test="content-slider-caption">{caption}</Caption>}
                    </CarouselCaptionWrapper>
                  </CarouselContentWrapper>
                </CarouselCard>
              ))}
          </Slider>
        </SliderWrapper>
      </ContentSliderWrapper>
    </MetaBallContainer>
  );
};
